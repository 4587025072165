@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#mapid{
  width: 100%;
  height:calc(100% - 280px);
  z-index: 0;
}

.app-container{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header{
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(/static/media/pattern-bg.a60a3c85.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
 
}
.info-container{
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 77%;
  height: 161px;
  top: 200px;
  background: #FFFFFF;
  box-shadow: 0px 50px 50px -25px rgba(0, 0, 0, 0.0983665);
  border-radius: 15px;    
  padding: 0 2rem;
  z-index: 3;
}

h1{
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 30px;
  letter-spacing: -0.285714px;
  color: #FFFFFF;
  margin: 2rem 0;
}

form{
  position: relative;
  width: 38.5%;
  height: 58px;
  
}

input[type=text]{
  width: calc(100% - 1rem);
  height: 100%;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 1rem;
  border-radius: 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #2C2C2C;
}

input[type=text]::-webkit-input-placeholder{
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #2C2C2C;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-left: 1rem;
}

input[type=text]:-ms-input-placeholder{
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #2C2C2C;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-left: 1rem;
}

input[type=text]::placeholder{
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #2C2C2C;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-left: 1rem;
}
.submit{
  position: absolute;
  right:0;
  top:0;
  bottom:0;
  
  width: 58px;
  background: #000000;
  border-radius: 0px 15px 15px 0px; 
  margin: 0;
  border:none;
  outline: none;
}

.submit:hover{
  background: #3F3F3F;
  transition: all .3s ease-in-out;
}
.info-container h4{
font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 14px;
letter-spacing: 1.75px;
text-transform: uppercase;
color: #2C2C2C;
mix-blend-mode: normal;
opacity: 0.5;
margin-top: 2rem;
}

.info-container p{
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -0.232143px;
  color: #2C2C2C;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.breakline{
  width: 1px;
  height: 70%;
  align-self: center;
  margin-left: 3rem;
  margin-right: .5rem;
  background: #000000;
  mix-blend-mode: normal;
  opacity: 0.15;  
}

@media (max-width:890px){
  h1{
    font-size: 26px;
  }

  form{
    width: 95%;
  }

  input[type=text]{
    font-size: 18px;
  }

  input[type=text]::-webkit-input-placeholder{
    font-size: 14px;
  }

  input[type=text]:-ms-input-placeholder{
    font-size: 14px;
  }

  input[type=text]::placeholder{
    font-size: 14px;
  }

  .info-container{
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    top:167px;
    height:260px;
    padding: 1rem;
  }

  .breakline{
    display: none;
  }

  .info-container h4{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 1.45833px;
    text-transform: uppercase;
    margin:0;
  }
  .info-container p{
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.178571px;
    margin: .4rem 0;
  }
}
